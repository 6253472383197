import axios from "axios";
import { Notify } from "vant";

// 重复请求拦截，需要进行请求路由判断，否则无法并发请求
var CancelToken = axios.CancelToken;
let pending = [];
let cancelPending = (config) => {
  pending.forEach((item, index) => {
    if (config) {
      if (item.UrlPath === config.url) {
        item.Cancel(); // 取消请求
        pending.splice(index, 1); // 移除当前请求记录
      }
    } else {
      item.Cancel(); // 取消请求
      pending.splice(index, 1); // 移除当前请求记录
    }
  });
};

// 基本实例
const service = axios.create({
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

service.interceptors.request.use(
  (request) => {
    // let token = store.getters.token;
    // if (token) {
    //   request.headers["token"] = token;
    // }
    cancelPending(request);
    // 加入url判断是否重复请求
    request.cancelToken = new CancelToken((res) => {
      pending.push({ UrlPath: request.url, Cancel: res });
    });
    if (
      request.data &&
      request.headers["Content-Type"] === "application/x-www-form-urlencoded" &&
      Object.prototype.toString.call(request.data) === "[object Object]"
    ) {
      request.data = JSON.stringify(request.data);
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description 接口返回数据说明
 * @version 1.0.0
 * code 状态码 200 => success
 * msg 返回信息
 * data 请求数据
 */
// response 拦截器
service.interceptors.response.use(
  ({ data }) => {
    if (data.isValidCodeLogin === false) {
      Notify({
        type: "danger",
        message: data.message,
      });
      // setTimeout(() => {
      //   window.location.href = "/#/login";
      // }, 500);
      return Promise.reject(data.message);
    }
    return data;
  },
  (error) => {
    // 后端处理问题，404时未返回message，导致报错
    if (error.message) {
      Notify({
        type: "danger",
        message: error.message,
      });
    }
    return Promise.reject(error);
  }
);

export default service;
