import request from "./axios.js";

// export const baseUrl = "https://whu.zonewee.com";
export const baseUrl = "https://xysc.whu.edu.cn";
const config = require('config')

/**
 * 微信授权页面跳转
 * @return 重定向
 */
export function authorize() {
  return request({
    url: `${config.cms.path}/a/thirdParty/authorize`,
    method: "GET",
  });
}

/**
 * 绑定用户名和openid
 * return 用户基本信息以及手机编号和会话ID，手机编号，会话ID需要前端存储
 */
export function bindUser(logincode, password, openId) {
  return request({
    url: `${config.cms.path}/a/thirdParty/bind/${logincode}/${password}/${openId}`,
    method: "GET",
  });
}

/**
 * 判断登录是否过期
 * @params uuid 手机编号
 * @params sid 会话ID
 * @return 用户基本信息以及手机编号和会话ID，手机编号，会话ID需要前端存储
 */
export function isLogin(data) {
  return request({
    url: `${config.cms.path}/a/thirdParty/validate/mobile/login`,
    method: "GET",
    params: data,
  });
}

/**
 * 获取今天天气信息
 * @params appid	    是	string	用户appid	注册开发账号
 * @params appsecret	是	string	用户appsecret
 * @params version	  是	string	接口版本标识	固定值: v6 每个接口的version值都不一样
 * @params cityid	    否	string	城市ID	请参考 城市ID列表
 * @params city	      否	string	城市名称	不要带市和区; 如: 青岛、铁西
 * @params ip	        否	string	IP地址	查询IP所在城市天气
 * @params callback	  否	string	jsonp参数	如: jQuery.Callbacks
 * @params vue	      否	string	跨域参数
 * @return
 */
export function tianqiapi() {
  return request({
    url: `https://tianqiapi.com/api?version=v6&appid=64645866&appsecret=U3fMgPaL`,
    method: "GET",
  });
}

/**
 * 学生登录获取当前所在班级学院名单列表信息
 * @params 无
 * @return avatarUrl 用户头像url
 * @return email  电子邮箱
 * @return userName 姓名
 * @return sex 性别
 * @return mobile 联系方式
 * @return fullName 所在班级
 * @return treeNames 所在组织架构
 */
export function studentFrom(sid, data) {
  return request({
    url: `${config.cms.path}/a/studentFrom/list?__ajax=json&__sid=${sid}`,
    method: "POST",
    data,
  });
}

/**
 * 学生登录获取当前当前周课程表
 * @params 无
 * @return list
 */
export function studentCourse(sid, data) {
  return request({
    url: `${config.cms.path}/a/train/studentCourse/student/list?__ajax=json&__sid=${sid}`,
    method: "POST",
    data,
  });
}

/**
 * 获取欢迎词
 * @params 无
 * @return 当前登录学生对应新闻欢迎辞ID
 */
export function getWelcomeId(sid) {
  return request({
    url: `${config.cms.path}/a/article/custom/getWelcomeId?__ajax=json&__sid=${sid}`,
    method: "GET",
  });
}

/**
 * 获取新闻列表
 * @params categoryCode A10003 学习指南  A10004 珞珈风华
 * @return list 指定栏目新闻列表
 */
export function newsList(sid, categoryCode) {
  return request({
    url: `${config.cms.path}/a/article/custom/newsList?__ajax=json&__sid=${sid}&categoryCode=${categoryCode}`,
    method: "GET",
  });
}

/**
 * 获取新闻详情
 * @params 新闻ID
 * @return info 新闻具体信息
 */
export function newsDetail(sid, newsId) {
  return request({
    url: `${config.cms.path}/a/article/custom/getArticleById?__ajax=json&__sid=${sid}&articleId=${newsId}`,
    method: "GET",
  });
}

/**
 * 获取不同模块的展示风格
 * @params dictType 模块编码
 * sys_teacher_introduce：教师介绍
 * sys_course_style：课程安排
 * sys_student_style：学员名单
 * @return dictValue 风格字段
 */
export function detailType(sid, dictType) {
  return request({
    url: `${config.cms.path}/a/sys/dictData/custom/listData?__ajax=json&__sid=${sid}&dictType=${dictType}`,
    method: "GET",
  });
}

/**
 * 获取教师详情
 * @params userCode 教师编码
 * @return 教师详情
 */
export function teacherDetail(sid, userCode) {
  return request({
    url: `${config.cms.path}/a/train/studentCourse/teacher/detail?__ajax=json&__sid=${sid}&userCode=${userCode}`,
    method: "GET",
  });
}

/**
 * 附件列表
 * @params bizKey 课程记录ID
 * @params bizType 固定值 studentCourse_file
 * @return 教师详情
 */
export function fileList(sid, bizKey, bizType) {
  bizType = 'studentCourse_file'
  return request({
    url: `${config.cms.path}/a/file/fileList?__ajax=json&__sid=${sid}&bizKey=${bizKey}&bizType=${bizType}`,
    method: "GET",
  });
}