const baseUrl = 'https://xysc.whu.edu.cn'

module.exports = {
  cms: {
    path: '/cms',
    target: baseUrl,
    ws: false,
    changeOrigin: true,
    onProxyReq(proxyReq) {
      proxyReq.removeHeader('origin')
    }
  },
}